import styled, { css } from 'styled-components';

// css `` Styles - START
const CartDropdownIsHidden = css`
    visibility: hidden;
    opacity: 0;
`;
// css `` Styles - END

export const CartDropdownContainer = styled.div`
    position: absolute;
    width: 240px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid black;
    background-color: white;
    border-radius: 2px;
    top: 90px;
    right: 40px;
    z-index: 5;
    visibility: visible;
    opacity: 1;
    transition: all 0.2s linear;

    @media screen and (max-width: 400px) {
        width: 80%;
        left: 50%;
        transform: translateX(-50%);
    }

    ${({ hidden }) => {
        return hidden ? CartDropdownIsHidden : null
    }}

    button {
        margin: 20px auto 0;
    }
`;

export const CartItems = styled.div`
    max-height: 240px;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .empty-message {
        margin: 50px auto;
        text-align: center;
        font-weight: 600;
    }
`;