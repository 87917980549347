// Imports from react and react-router-dom
import React, { useEffect, lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

// Import scss and Components
import './app.scss';
import ErrorBoundary from './components/error-boundary/error-boundary.component';
import Loader from './components/with-spinner/loader';
import Header from './components/header/header.component';

// Import useSelector, useDispatch from react-redux
import { useSelector, useDispatch } from 'react-redux';
// Import selector to select Current User
import { selectCurrentUser } from './redux/user/user.selectors';
// Import action from user.actions
import { checkUserSession } from './redux/user/user.actions';

// Imports End -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_

const HomePage = lazy(() => import('./pages/homepage/homepage.component'))
const ShopPage = lazy(() => import('./pages/shop/shop.component'))
const SignInAndSignUpPage = lazy(() => import('./pages/sign-in-and-sign-up/sign-in-and-sign-up.component'))
const CheckoutPage = lazy(() => import('./pages/checkout/checkout.component'))
const NoMatch404 = lazy(() => import('./pages/404/NoMatch.component'))

const App = () => {

  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkUserSession());
  }, [dispatch])

  return (
    <>
      <Header />
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          <Routes>
            {/* Home Page */}
            <Route path='/' element={<HomePage />} />
            {/* Shop Page */}
            <Route path='/shop/*' element={<ShopPage />} />
            {/* Contact Page */}
            <Route path='/contact' element={<ContactPage />} />
            {/* Checkout Page */}
            <Route path='/checkout' element={<CheckoutPage />} />
            {/* Sign in and Sign up Page */}
            <Route path='/signin'
              element={
                currentUser ? < Navigate to='/' /> : < SignInAndSignUpPage />
              } />
            <Route path='*' element={<NoMatch404 />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </>
  );
}

export default App;





// --------- Contact Page
const ContactPage = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h2 style={{ marginTop: '100px' }}>No Contact Information</h2>
    </div>
  )
}