import React, { useState } from 'react';
import { ReactComponent as Logo } from '../../assets/084 crown.svg';
import CartIcon from '../cart-icon/cart-icon.component';
import CartDropdown from '../cart-dropdown/cart-dropdown.component';
import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { signOutStart } from '../../redux/user/user.actions';
// Import Styled Components
import { HeaderContainer, LogoContainer, OptionsContainer, OptionLink } from './header.styles'
// Import Contexts
import CartContext from '../../contexts/cart/cart.context';

function Header() {
    const [hidden, setHidden] = useState(true);
    const toggleHidden = () => setHidden(!hidden)

    const currentUser = useSelector(selectCurrentUser)
    const dispatch = useDispatch();

    return (
        <HeaderContainer>
            <LogoContainer to='/'>
                <Logo />
            </LogoContainer>
            <OptionsContainer>
                <OptionLink to='/shop'>SHOP</OptionLink>
                <OptionLink to='/contact'>CONTACT</OptionLink>
                {
                    currentUser ?
                        <OptionLink as='div' onClick={() => dispatch(signOutStart())}>SIGN OUT</OptionLink>
                        : <OptionLink to='/signin'>SIGN IN</OptionLink>
                }
                <CartContext.Provider value={{ toggleHidden }}>
                    <CartIcon />
                </CartContext.Provider>
            </OptionsContainer>
            <CartContext.Provider value={{ hidden, toggleHidden }}>
                <CartDropdown />
            </CartContext.Provider>
        </HeaderContainer>
    );
}

export default Header;