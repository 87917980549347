// Import combineReducers() from redux
import { combineReducers } from "redux";
// Redux Persist Imports
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// Reducers
import userReducer from "./user/user.reducer";
import cartReducer from "./cart/cart.reducer";
import directoryReducer from "./directory/directory.reducer";
import shopReducer from "./shop/shop.reducer";
// Import getPersistConfig to enter deeper path of whitelist or blacklist in persistConfig
import { getPersistConfig } from 'redux-deep-persist';

const rootReducer = combineReducers({
    user: userReducer,
    cart: cartReducer,
    directory: directoryReducer,
    shop: shopReducer
});

const persistConfig = getPersistConfig({
    key: 'root',
    storage,
    whitelist: ['cart.cartItems'],
    rootReducer
})


export default persistReducer(persistConfig, rootReducer);