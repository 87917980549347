import styled from 'styled-components';

export const CartItemContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;

export const ForImg = styled.div`
    width: 60px;
    height: 80px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`;

export const ItemDetails = styled.div`
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px 20px;
`;

export const Name = styled.div`
    font-size: 16px;
`;