import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const HeaderContainer = styled.div`
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
`;

export const LogoContainer = styled(Link)`
    height: 100%;
    padding: 25px 0;
    display: flex;
    align-items: center;
`;

export const OptionsContainer = styled.div`
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const OptionLink = styled(Link)`
    font-size: 16px;
    padding: 10px 0;
    margin: 0 15px;
    cursor: pointer;
    text-shadow: 0.12em 0.12rem 0 transparent;
    transition: all 0.2s linear;
    &:hover {
        transform: translateY(-0.1rem);
        text-shadow: 0.12rem 0.12rem 0.1rem rgba(0, 0, 0, 0.5);
    }
    @media screen and (max-width: 576px) {
        font-size: 12px;
        margin: 0 7px;
    }
`;