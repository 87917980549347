import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';


const config = {
    apiKey: 'AIzaSyC400X2Xmo4bglTTFNwEKSoyolp1u8oFNY',
    authDomain: "crwn-db-5a780.firebaseapp.com",
    projectId: "crwn-db-5a780",
    storageBucket: "crwn-db-5a780.appspot.com",
    messagingSenderId: "977663774982",
    appId: "1:977663774982:web:14f9345457165a31cdce87",
    measurementId: "G-9KKBJVE2C4"
}

firebase.initializeApp(config);


export const getUserCartRef = async userId => {
    const cartsRef = firestore.doc(`carts/${userId}`);
    const snapShot = await cartsRef.get();
    if (!snapShot.exists) {
        // const cartDocRef = firestore.collection('carts').doc();
        const cartDocRef = firestore.doc(`carts/${userId}`);
        await cartDocRef.set({ userId, cartItems: [] });
        return cartDocRef;
    } else {
        return cartsRef;
    }
};

export const createUserProfileDocument = async (userAuth, additionalData) => {
    // If user doesn't exist, end createUserProfileDocument Function
    if (!userAuth) return;
    // Else

    const userRef = firestore.doc(`users/${userAuth.uid}`);
    const snapShot = await userRef.get();

    if (!snapShot.exists) {
        const { displayName, email } = userAuth;
        const createdAt = new Date();
        try {
            await userRef.set({
                displayName,
                email,
                createdAt,
                ...additionalData
            });
        } catch (error) {
            console.log('error creating user', error.message);
        }
    }

    return userRef;
};

export const convertCollectionsSnapshotToMap = (collections) => {
    const transformedCollection = collections.docs.map(
        doc => {
            const { title, items } = doc.data();

            return {
                routeName: encodeURI(title.toLowerCase()),
                id: doc.id,
                title,
                items
            }
        }
    )

    return transformedCollection.reduce((accumulator, collection) => {
        accumulator[collection.title.toLowerCase()] = collection;
        return accumulator;
    }, {})
}

export const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(
            userAuth => {
                unsubscribe();
                resolve(userAuth);
            },
            reject
        )
    })
}


export const auth = firebase.auth();
export const firestore = firebase.firestore();

export const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => auth.signInWithPopup(googleProvider);

export default firebase;