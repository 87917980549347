import UserActionTypes from "./user.types"

// Google Sign In Start
export const googleSignInStart = () => ({
    type: UserActionTypes.GOOGLE_SIGN_IN_START
})

// Sign In Success action for Google And Email
export const signInSuccess = user => ({
    type: UserActionTypes.SIGN_IN_SUCCESS,
    payload: user
})

// Sign In Failure action for Google And Email
export const signInFailure = error => ({
    type: UserActionTypes.SIGN_IN_FAILURE,
    payload: error
})

// Email Sign In Start
export const emailSignInStart = emailAndPassword => ({
    type: UserActionTypes.EMAIL_SIGN_IN_START,
    payload: emailAndPassword
})

// Check if User Session exists or Not
export const checkUserSession = () => ({
    type: UserActionTypes.CHECK_USER_SESSION
})

// ---------- SIGN OUT ---------- 

// Sign Out Start
export const signOutStart = () => ({
    type: UserActionTypes.SIGN_OUT_START
})

// Sign Out Success
export const signOutSuccess = () => ({
    type: UserActionTypes.SIGN_OUT_SUCCESS
})

// Sign Out Failure
export const signOutFailure = error => ({
    type: UserActionTypes.SIGN_OUT_FAILURE,
    payload: error
})

// ---------- SIGN UP ---------- 

// Sign Up Start
export const signUpStart = userCredentials => ({
    type: UserActionTypes.SIGN_UP_START,
    payload: userCredentials
})

// Sign Up Success
export const signUpSuccess = ({ user, additionalData }) => ({
    type: UserActionTypes.SIGN_UP_SUCCESS,
    payload: { user, additionalData }
})

// Sign Up Failure
export const signUpFailure = error => ({
    type: UserActionTypes.SIGN_UP_FAILURE,
    payload: error
})