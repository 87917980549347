import React, { memo } from "react";
import { CartItemContainer, ForImg, ItemDetails, Name } from './cart-item.styles';

const CartItem = ({ item: { imageUrl, price, name, quantity } }) => (
    <CartItemContainer>
        <ForImg style={{ backgroundImage: `url(${imageUrl})` }} />
        <ItemDetails>
            <Name>{name}</Name>
            <span>{quantity} x ${price}</span>
        </ItemDetails>
    </CartItemContainer>
)

export default memo(CartItem);