// Imports of react, scss, files
import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { CartIconContainer, ShoppingIconContainer, ItemCount } from './cart-icon.styles';
import { ReactComponent as ShoppingIcon } from '../../assets/122 shopping-bag.svg'
// Import Actions from Cart Actions
import { selectCartItemsCount } from '../../redux/cart/cart.selectors';
import CartContext from '../../contexts/cart/cart.context';

const CartIcon = () => {
    const [isClicked, setIsClicked] = useState(false);

    const itemCount = useSelector(selectCartItemsCount);
    
    const { toggleHidden } = useContext(CartContext);
    
    function cartIconClick(e) {
        toggleHidden()
        setIsClicked(true);
        setTimeout(() => setIsClicked(false), 200)
    }


    return (
        <CartIconContainer onClick={cartIconClick} >
            <ShoppingIconContainer clicked={isClicked}>
                <ShoppingIcon />
            </ShoppingIconContainer>
            <ItemCount clicked={isClicked}>
                {itemCount}
            </ItemCount>
        </CartIconContainer>
    );
}

export default CartIcon;