import styled, { css } from 'styled-components';

// css `` Styles - START
const ShoppingIconContainerIsClicked = css`
    svg {
            -webkit-font-smoothing: subpixel-antialiased;
            transform: scale(0.8, 0.8);
            zoom: 1;
    }
`;
const ItemCountIsClicked = css`
    -webkit-font-smoothing: subpixel-antialiased;
    transform: scale(0.8, 0.8) translate3d(-50%, 0, 0);
    zoom: 1;
`;
// css `` Styles - END

export const CartIconContainer = styled.div`
    height: 45px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    user-select: none;
    margin-left: 15px;

    @media screen and (max-width: 576px) {
        margin-left: 7px;
    }
`;

export const ShoppingIconContainer = styled.span`
    display: flex;

    svg {
        width: 24px;
        height: 24px;
        transition: all 0.2s ease-in-out;
        transform: translate3d(0, 0, 0);
    }

    ${({ clicked }) => {
        return clicked ? ShoppingIconContainerIsClicked : null
    }}
`;

export const ItemCount = styled.div`
    position: absolute;
    left: 50%;
    font-size: 10px;
    font-weight: bold;
    bottom: 12px;
    transition: all 0.2s ease-in-out;
    transform: translate3d(-50%, 0, 0);

    ${({ clicked }) => {
        return clicked ? ItemCountIsClicked : null
    }}
`;