import React, { useContext } from "react";
import { CartDropdownContainer, CartItems } from './cart-dropdown.styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CustomButton from '../custom-button/custom-button.component';
import CartItem from "../cart-item/cart-item.component";

import { selectCartItems } from "../../redux/cart/cart.selectors";
import CartContext from "../../contexts/cart/cart.context";

const CartDropdown = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // - Redux States
    const cartItems = useSelector(selectCartItems);
    
    const { hidden, toggleHidden } = useContext(CartContext);

    // Function - Go To Checkout Page and Close Cart Dropdown
    const goToCheckout = () => {
        navigate('/checkout');
        toggleHidden()
    }

    return (
        <CartDropdownContainer hidden={hidden} >
            <CartItems>
                {
                    cartItems.length ?
                        cartItems.map(cartItem => <CartItem key={cartItem.id} item={cartItem} />)
                        : <span className="empty-message">Shopping cart is empty</span>
                }
            </CartItems>
            {location.pathname === '/checkout' ? null :
                <CustomButton onClick={goToCheckout}>GO TO CHECKOUT</CustomButton>
            }
        </CartDropdownContainer>
    )
};

export default CartDropdown;